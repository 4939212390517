import styled from 'styled-components';

export const StyledDiv = styled.div`
  margin: 0 auto;
  max-width: 1918px;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1.2px;
  p {
    color: #3C3C3B;
    margin: 0;
    font-weight: 300;
  }
  span {
    color: #F28E00;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

export const CaseContainer = styled.div`
  display: flex;
  min-height: 150px;
  padding: 10px 0;
  &:nth-child(even){
    flex-direction: row-reverse;
  }

  @media (min-width: 900px){
    padding: 0;
    min-height: 560px;
  }
  @media (min-width: 1200px){
    min-height: 775px;
  }
`;
export const CaseInner = styled.div`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  @media (min-width: 900px){
    width: 50%;
}
`

export const CaseText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  margin: 0 auto;
  height: 90%;
  color: #3C3C3B;
  padding: 10px 55px;
  h2 {
    z-index: 3000;
    font-size: 30px;
    line-height: 38px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 15px;
    @media (min-width: 900px) {
      font-size: 40px;
      line-height: 46px;
    }
  }
  h3 {
    color: #F28E00;
    z-index: 3000;
    font-size: 24px;
    line-height: 26px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 15px;
    @media (min-width: 900px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  @media (min-width: 720px){
    padding: 0 60px;
  }
  @media (min-width: 1224px){
    padding: 0 130px;
    
  }
  @media (min-width: 1600px){
    padding: 0 150px;
  }
`
export const CaseBG = styled.div`
  display: none;
  @media (min-width: 1024px){
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30% 30%;
    width: 100%;
    height: 100%;  
  }
  @media (min-width: 1662px){
    background-position: 50% 50%;
  }
`

export const StyledImg = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
  min-height: 250px;
  display: none;
  @media (min-width: 900px){
    max-height: 560px;
    display: block;
  }
  @media (min-width: 1200px){
    max-height: 775px;
  }
`