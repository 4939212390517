import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyledDiv, ItemsContainer, StyledItem, TextOutter, StyledText } from './styledColumns'
import { Container, Headline, Subline, Paragraph, StyledSection, StyledButton } from '../layout/layoutComponents'

const Columns= () => (
  <StaticQuery
    query={graphql`
    query {
      craftHomepageHomepageEntry {
        columnsHeadline
        columnsSubline
        columnsSublineTwo
        columns {
          ... on Craft_columns_columns_Entry {
            id
            excerpt
            slug
            title
            image {
              url
            }
          }
        }
      }
    }
  `}

    render={({craftHomepageHomepageEntry}) => (
        <StyledSection id="transformation">
          <Container>
                <StyledDiv>
                  <Headline>{craftHomepageHomepageEntry.columnsHeadline}</Headline>
                  <Subline style={{marginBottom: `0`}}>{craftHomepageHomepageEntry.columnsSubline}</Subline>
                  <Paragraph style={{textAlign: `center`, marginTop: `50px`}}>{craftHomepageHomepageEntry.columnsSublineTwo}</Paragraph>
                  <ItemsContainer>
                    {craftHomepageHomepageEntry.columns.map((column, index) => (
                      <StyledItem key={column.id}>
                        <img 
                          src={column.image[0].url} 
                          alt='alt' 
                          style={{
                            margin: index === 0 ? `0 auto 76px` : null,
                            paddingTop: index === 0 ? `89px` : null,
                            maxHeight: index === 0 ? `320px` : null,
                            maxWidth: index === 0 ? `260px` : null,
                            }}
                          />
                        <TextOutter>
                          <StyledText>
                            <h4 style={{
                              textTransform: `uppercase`,
                              margin: `0`,
                              padding: `0`,
                              letterSpacing: `1.2px`,
                              lineHeight: `28px`
                            }}>{column.title}</h4>
                            <Paragraph>{column.excerpt}</Paragraph>
                          </StyledText>
                            <StyledButton style={{alignSelf: `flex-end`}}>
                              <Link 
                                to={column.slug}
                              >read more</Link>
                            </StyledButton>
                        </TextOutter>
                      </StyledItem>
                    ))}
                  </ItemsContainer>
                </StyledDiv>
          </Container>
        </StyledSection> 
    )}

  />
);

export default Columns;