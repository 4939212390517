import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyledDiv, CaseContainer, CaseInner, CaseText, CaseBG, StyledImg } from './styledCases'
import { Headline, Subline, Paragraph, StyledSection, StyledButton } from '../layout/layoutComponents'


const Cases= () => (
  <StaticQuery
    query={graphql`
    query casesEN {
      craftHomepageHomepageEntry {
        casesHeadline
        casesSubline
        cases {
          ... on Craft_cases_cases_Entry {
            id
            image {
              url
            }
            title
            subtitle
            ctaButton
            slug
            excerpt
            backgroundImage {
              url
            }
          }
        }
      }
    }
    `}

    render={({craftHomepageHomepageEntry}) => (
        <StyledSection id={'cases'}>
              <StyledDiv>
                <Headline>{craftHomepageHomepageEntry.casesHeadline}</Headline>
                <Subline>{craftHomepageHomepageEntry.casesSubline}</Subline>
                {craftHomepageHomepageEntry.cases.map(item => (
                  <CaseContainer id={item.id}>
                    <StyledImg src={item.image[0].url} alt={item.title} />
                    <CaseInner>
                      <CaseBG style={{backgroundImage:`url(${item.backgroundImage[0].url})`}}/>
                      <CaseText >
                        <h3>{item.title}</h3>
                        <h2>{item.subtitle}</h2>
                        <Paragraph style={{zIndex: `999`}}>{item.excerpt}</Paragraph>
                        <StyledButton style={{alignSelf: `flex-end`}}>
                          <Link to={item.slug}>{item.ctaButton}</Link>
                        </StyledButton>
                      </CaseText>
                    </CaseInner>
                  </CaseContainer>
                ))}
                <div style={{paddingTop: `50px`}}>
                  <Paragraph style={{textAlign: `center`}}>You have a certain challenge in mind? <Link to='/contact' style={{color: `#F28E00`, textDecoration: `none`, cursot: `pointer`}}>Ask us</Link> about our experience in that area.</Paragraph>
                </div>
                {/* <StyledButton style={{margin: `0 auto`, marginTop: `37px`}}>
                  <Link to={'/contact'}>contact us</Link> 
                </StyledButton> */}
              </StyledDiv>
        </StyledSection>
    )}

  />
);

export default Cases;
