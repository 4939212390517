import styled from 'styled-components';

export const StyledDiv = styled.div`
  padding: 0 0 85px 0;
  margin: 0 auto;
  text-align: center;
  max-width: 1120px;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1.2px;
  p {
    color: #3C3C3B;
    margin: 0;
    font-weight: 300;
  }
  span {
    color: #F28E00;
    text-transform: uppercase;
    font-weight: 600;
  }
`


export const BusinessContainer = styled.div`
  margin: 0 auto;
  flex: 1 0 auto;
  padding: 0 30px;
  @media (min-width: 1480px){
    padding: 0 5px;
  }
`
export const BusinessImage = styled.div`
  height: 250px;
  margin: 0 auto;
  display: flex;
  img {
    max-width: 220px;
    height: auto;
    max-height: 200px;
    height: auto;
    align-self: flex-end;
  }
`
export const BusinessText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-bottom: 15px;
  h5 {
    text-transform: capitalize;
    text-align: initial;
    color: #F28E00;
    font-weight: 500;
    font-size: 16px;
    align-self: start;
  }
  span {
    font-size: 16px;
  }
  @media (min-width: 768px) {
    min-height: 390px;
  }
  @media (min-width: 1024px) {
    min-height: 440px;
  }
  @media (min-width: 1480px) {
    min-height: 400px;
  }
`
export const BusinessInner = styled.div`
  margin: 0 auto;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 480px;
  @media(min-width: 507px) {
    min-height: 320px;
  }
  @media (min-width: 768px) {
    min-height: 530px;
  }
  @media (min-width: 1490px) {
    min-height: 480px;
  }
  img {
    width: 100%;
    height: auto;
  }
`