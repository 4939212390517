import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import HomeHero from '../components/hero/heroEN'
import TextBox from '../components/textBox/textBoxEN'
import Columns from '../components/columns/columnsEN'
import Transformation from '../components/transformation/transformationEN'
import Cases from '../components/cases/casesEN'
import Businesses from '../components/businesses/businessesEN'
import Team from '../components/team/team'
import Contact from '../components/contact/contact'
import Blog from '../components/blog/blogEN'
import Partners from '../components/partners/partners'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <>
      <HomeHero />
      <TextBox />
      <Columns />
      <Transformation />
      <Cases />
      <Businesses />
      <Team /> 
      <Contact />
      <Blog />
      <Partners />
    </>
      
  </Layout>
)

export default IndexPage;