import React, { useState } from "react"
import ContactInfo from "./contactInfo"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const ContactContainer = styled.div`
  max-width: 720px;
  padding: 55px 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`

const ContactNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 25px;
  @media (min-width: 1024px) {
    border-right: 1px solid #f28e00;
    border-bottom: none;
    flex-direction: column;
    padding-right: 110px;
  }
`

const ContactBtn = styled.button`
  border: 1px solid #f28e00;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  width: 100px;
  height: 44px;
  margin: 15px 10px;
  outline: none;
  box-shadow: 0px 3px 6px #00000029;
  &:hover {
    transition: background-color 0.4s ease;
    transition: color 0.1s ease;
    background-color: #f28e00;
    color: #fff;
    cursor: pointer;
  }
  @media (min-width: 531px) {
    width: 150px;
    height: 53px;
    margin: 15px 10px;
  }
  @media (min-width: 720px) {
    font-size: 16px;
    line-height: 20px;
    width: 230px;
    height: 53px;
  }
`

const ToggleContact = ({ contacts }) => {
  const [currentContact, setCurrentContact] = useState(contacts[0])
  return (
    <ContactContainer>
      <ContactNav>
        {contacts.map(contact => (
          <ContactBtn
            key={contact.id}
            onClick={() => setCurrentContact(contact)}
          >
            {contact.title}
          </ContactBtn>
        ))}
      </ContactNav>
      <ContactInfo
        id={currentContact.id}
        name={currentContact.title}
        street={currentContact.street}
        city={currentContact.city}
        country={currentContact.country}
      />
    </ContactContainer>
  )
}

export default ToggleContact
