import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Headline, Subline, Paragraph, StyledSection } from '../layout/layoutComponents';
import styled from 'styled-components';

const SyteldDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 1120px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1.2px;
  span {
    color: #F28E00;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const TextContainer = styled.div`
`;

const TextBox= () => (
  <StaticQuery
    query={graphql`
    query textBox {
      craftHomepageHomepageEntry {
        textBox
        textBoxHeadline
        textBoxSubline
        ctaText
      }
    }
  `}

    render={({craftHomepageHomepageEntry}) => (
        <StyledSection>
          <Container>
              <SyteldDiv>
                <Headline>{craftHomepageHomepageEntry.textBoxHeadline}</Headline>
                <Subline>{craftHomepageHomepageEntry.textBoxSubline}</Subline>
                <TextContainer>
                  <Paragraph style={{textAlign: `center`}}>{craftHomepageHomepageEntry.textBox}</Paragraph>
                  <span>{craftHomepageHomepageEntry.ctaText}</span>
                </TextContainer>
              </SyteldDiv>
          </Container>
        </StyledSection>
    )}

  />
);

export default TextBox;