import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Headline, Subline, H4, Paragraph, StyledSection } from '../layout/layoutComponents';
import styled from 'styled-components';


const SyteldDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 1120px;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1.2px;
`

const ResponsiveDiv = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 96px;
  font-weight: 300;
  @media (min-width: 1240px) {
    flex-direction: row;
  }
`
const TextContainer = styled.div`
  max-width: 344px;
  margin-bottom: 30px;
`
const TextContainerCenter = styled.div`
  max-width: 344px;
  margin-bottom: 30px;
  @media (min-width: 1240px) {
    H4 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`

const ImgContainer = styled.div`
  margin-bottom: 30px;
  img {
    max-width: 207px;
    @media (min-width: 475px) {
      max-width: 300px;
      padding: 20px 30px;
    margin: 10px 40px;
    }
    @media (min-width: 1267px) {
      max-width: 400px;
    }
    @media (min-width: 1603px) {
      max-width: 500px;
    }
  }
`
const Transformation= () => (
  <StaticQuery
    query={graphql`
    query transformation {
      craftHomepageHomepageEntry {
        transformationHeadline
        transformationSubline
        transformationImg {
          url
        }
        transformationIntro
        transformationIntroTwo
        Agile
        digital
        lean
      }
    }
  `}

    render={({craftHomepageHomepageEntry}) => (
        <StyledSection id={'how'} style={{backgroundColor: `rgba(156, 158, 159, 0.2)`}}>
          <Container>
                <SyteldDiv>
                  <Headline>{craftHomepageHomepageEntry.transformationHeadline}</Headline>
                  <Subline>{craftHomepageHomepageEntry.transformationSubline}</Subline>
                  <H4 style={{textAlign: `center`, marginTop: `54px`}}>{craftHomepageHomepageEntry.transformationIntro}</H4>
                  <Paragraph style={{textAlign: `center`, maxWidth: '1070px'}}>{craftHomepageHomepageEntry.transformationIntroTwo}</Paragraph>
                </SyteldDiv>
                <ResponsiveDiv>
                  <TextContainer style={{marginBottom: '50px'}}>
                    <H4 end bold color="blue">A Lean Mind</H4>
                    <Paragraph end color="blue"> {craftHomepageHomepageEntry.lean}</Paragraph>
                  </TextContainer>
                  <ImgContainer>
                    <img src={craftHomepageHomepageEntry.transformationImg[0].url} alt="placeholder" />
                  </ImgContainer>
                  <TextContainer >
                    <H4 bold>A Digital Sense</H4>
                    <Paragraph>{craftHomepageHomepageEntry.digital}</Paragraph>
                  </TextContainer>
                  <TextContainerCenter>
                    <H4 bold color="orange">An Agile Flow</H4>
                    <Paragraph color="orange">{craftHomepageHomepageEntry.Agile}</Paragraph>
                  </TextContainerCenter>
                </ResponsiveDiv>
          </Container>
        </StyledSection>
    )}

  />
);

export default Transformation