import styled from 'styled-components'

export const StyledDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  padding-bottom: 100px;
`
export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  justify-items: center;
  text-align: left;
  font-size: 20px;
  color: #3C3C3B;
  @media (min-width: 720px) {
    padding: 60px;
  };
  @media (min-width: 1240px) {
    flex-direction: row;
  }
`  

export const StyledItem = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  @media (min-width: 768px){
    flex-direction: row;
  };
  @media (min-width: 1240px){
    flex-direction: column;
  }
  img {
    max-width: 270px;
    height: auto;
    @media (min-width: 470px){ 
      max-width: 380px;
      max-height: 395px;
    } 
  }
`
export const TextOutter = styled.div`
  max-width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    min-height: 250px;
  };
`
export const StyledText = styled.div`
  margin-top: 0;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  @media (min-width: 768px) {
    margin-top: 70px;
    margin-bottom: 0;
  };
  @media (min-width: 1240px){
    margin-top: 0;
  }
`  