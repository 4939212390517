import React from 'react'
import { Link } from 'gatsby'
import { BusinessContainer, BusinessInner, BusinessImage, BusinessText} from './styledBusinesses'
import { Paragraph, StyledButton, H4 } from '../layout/layoutComponents'

const BusinessItem = ({ src, header, title, excerpt, slug }) => (
  <BusinessContainer>
    <BusinessInner>
      <BusinessImage>
        <img src={src} alt="placeholder"/>
      </BusinessImage>
      <BusinessText>
        <h5>{header}</h5>
        <H4>{title}</H4>
        <Paragraph>{excerpt}</Paragraph>
      </BusinessText>
      <StyledButton style={{alignSelf: `flex-end`}}><Link to={slug}>get a first answer</Link></StyledButton>
    </BusinessInner>
</BusinessContainer>  
)


export default BusinessItem