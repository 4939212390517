import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Headline, Subline, StyledSection } from '../layout/layoutComponents';
import { TeamNextArrow, TeamPrevArrow } from '../slider/slider'
import TeamMember from './teamMember'
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const SyteldDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1.2px;
`;

const TeamContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`

const TeamMemberContainer = styled.div`
  display: flex;
`

const TeamMemberBlock = styled.div`
  margin: 0 auto;
  width: 85%;
  @media (min-width: 425px) {
      width: 70%;
    }
  @media (min-width: 585px) {
    width: 65%;
  }
  @media (min-width: 1022px) {
    width: 85%;
  } 
`

const settings = {
  autoplay: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1022,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      }
    }
  ]
};

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <TeamNextArrow role="arrow"
      className={className}
      onClick={onClick}
      style={{top: '180px'}}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <TeamPrevArrow role="arrow"
      className={className}
      onClick={onClick}
      style={{top: '180px'}}
    /> 
  );
}


const Team = () => (
  <StaticQuery
    query={graphql`
    query team {
      craftHomepageHomepageEntry {
        teamHeadline
        teamSubline
        team {
          ... on Craft_team_team_Entry {
            id
            teamName
            teamRole
            teamIntro
            phoneNumber
            email
            image {
              url
            }
          }
        }
      }
    }
    `}

    render={({craftHomepageHomepageEntry}) => (
        <StyledSection>
          <Container>
              <SyteldDiv>
                <Headline>{craftHomepageHomepageEntry.teamHeadline}</Headline>
                <Subline>{craftHomepageHomepageEntry.teamSubline}</Subline>
                <TeamContainer>

                <Slider {...settings}>

                  {craftHomepageHomepageEntry.team.map(member => (
                    <TeamMemberContainer>
                      <TeamMemberBlock>

                        <TeamMember 
                          src={member.image[0].url}
                          intro={member.teamIntro}
                          alt={member.teamName}
                          name={member.teamName}
                          role={member.teamRole}
                          mail={member.email}
                          phone={member.phoneNumber}
                        />
                      </TeamMemberBlock>
                    </TeamMemberContainer>
                  ))}
                </Slider>
                </TeamContainer>
              </SyteldDiv>
          </Container>
        </StyledSection>
    )}

  />
);

export default Team;