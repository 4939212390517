import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import BusinessItem from './businessItem'
import { StyledDiv } from './styledBusinesses'
import { Container, Headline, Subline, StyledSection  } from '../layout/layoutComponents'
import { DotsList, NextArrow, PrevArrow } from '../slider/slider'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


const settings = {
  autoplay: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  dots: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  appendDots: dots => (
    <div
    >
      <DotsList> {dots} </DotsList>
    </div>
  ),
  responsive: [
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 3
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      }
    }
  ]
};


function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow role="arrow"
      className={className}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <PrevArrow role="arrow"
      className={className}
      onClick={onClick}
    /> 
  );
}


const Businesses = () => (
  <StaticQuery
    query={graphql`
    query businesses {
      craftHomepageHomepageEntry {
        businessesHeadline
        businessesSubline
        businesses {
          ... on Craft_businesses_businesses_Entry {
            id
            image {
              url
            }
            slug
            title
            header
            excerpt
          }
        }
      }
    }
  `}

    render={({craftHomepageHomepageEntry}) => (
        <StyledSection id={'businesses'} style={{backgroundColor: `rgba(156, 158, 159, 0.2)`}}>
          <Container>
              <StyledDiv>
                <Headline>{craftHomepageHomepageEntry.businessesHeadline}</Headline>
                <Subline>{craftHomepageHomepageEntry.businessesSubline}</Subline>
                <Slider {...settings}>
                  {craftHomepageHomepageEntry.businesses.map(item => (
                    <BusinessItem
                      src={item.image[0].url}
                      header={item.header}
                      title={item.title}
                      excerpt={item.excerpt}
                      slug={item.slug}
                    />
                  ))}
                </Slider>
              </StyledDiv>
          </Container>
        </StyledSection>
    )}

  />
);

export default Businesses;