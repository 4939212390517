import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import {
  Container,
  Headline,
  Subline,
  StyledButton,
} from "../layout/layoutComponents"
import styled from "styled-components"
import leftpic from "./left.png"
import rightpic from "./right.png"
import ToggleContact from "./showContact"

const StyledSection = styled.section`
  position: relative;
  overflow: hidden;
  padding: 35px 10px;
  @media (min-width: 720px) {
    padding: 50px 0;
  }
`
const Right = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 0px;
    right: -359px;
    height: 600px;
    width: 600px;
    background: url(${rightpic});
    background-size: cover;
  }
  @media (min-width: 1440px) {
    top: -360px;
    right: -650px;
    height: 1200px;
    width: 1200px;
  }
`
const Left = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 0px;
    left: -359px;
    height: 600px;
    width: 600px;
    background: url(${leftpic});
    background-size: cover;
  }
  @media (min-width: 1440px) {
    top: -360px;
    left: -650px;
    height: 1200px;
    width: 1200px;
  }
`

const SyteldDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 1120px;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1.2px;
  p {
    color: #3c3c3b;
    margin: 0;
    font-weight: 300;
  }
  span {
    color: #f28e00;
    text-transform: uppercase;
    font-weight: 600;
  }
`

// const ContactContainer = styled.div`
//   max-width: 720px;
//   padding: 55px 0;
//   display: flex;
//   flex-direction: column;
//   margin: 0 auto;
//   justify-items: center;
//   justify-content: center;
//   @media (min-width: 1024px) {
//     flex-direction: row;
//   }
// `;

// const ContactNav = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   padding-bottom: 25px;
//   @media (min-width: 1024px) {
//     border-right: 1px solid #F28E00;
//     border-bottom: none;
//     flex-direction: column;
//     padding-right: 110px;
//   }
// `;

// const ContactBtn = styled.button`
//   border: 1px solid #F28E00;
//   background-color: transparent;
//   text-transform: uppercase;
//   font-size: 11px;
//   line-height: 16px;
//   font-weight: 500;
//   width: 70px;
//   height: 44px;
//   margin: 15px auto;
//   outline: none;
//   box-shadow: 0px 3px 6px #00000029;
//   &:hover {
//     transition: background-color 0.4s ease;
//     transition: color 0.1s ease;
//     background-color: #F28E00;
//     color: #fff;
//     cursor: pointer;
//   }
//   @media (min-width: 720px) {
//     font-size: 16px;
//     line-height: 20px;
//     width: 230px;
//     height: 53px;
//   }
// `;
const Contact = () => (
  <StaticQuery
    query={graphql`
      query contact {
        craftHomepageHomepageEntry {
          contactHeadline
          contactSubline
          contact {
            ... on Craft_contactAdress_contactAdress_Entry {
              id
              title
              street
              city
              country
            }
          }
        }
      }
    `}
    render={({ craftHomepageHomepageEntry }) => (
      <StyledSection id={"contact"}>
        <Left />
        <Right />
        <Container>
          <SyteldDiv>
            <>
              <Headline>{craftHomepageHomepageEntry.contactHeadline}</Headline>
              <Subline>{craftHomepageHomepageEntry.contactSubline}</Subline>
            </>
            <ToggleContact contacts={craftHomepageHomepageEntry.contact} />
            <StyledButton style={{ margin: `0 auto` }}>
              <Link to="/contact">more about avance</Link>
            </StyledButton>
          </SyteldDiv>
        </Container>
      </StyledSection>
    )}
  />
)

export default Contact
