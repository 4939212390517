import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import {
  Container,
  Headline,
  Subline,
  StyledSection,
  StyledButton,
} from "../layout/layoutComponents"
import {
  SytledDiv,
  BlogFeed,
  BlogItem1,
  BlogItem2,
  BlogItem3,
  BlogItem4,
  BlogItem5,
  BlogImage,
  BlogOverlay,
  BlogLink,
} from "./styledBlog"

const Blog = () => (
  <StaticQuery
    query={graphql`
      query {
        craftHomepageHomepageEntry {
          blogHeadline
          blogSubline
          blog {
            ... on Craft_blog_blogNews_Entry {
              id
              image {
                url
              }
              title
              slug
              excerpt
            }
          }
        }
      }
    `}
    render={({ craftHomepageHomepageEntry }) => (
      <StyledSection id={"whats-up"}>
        <Container>
          <SytledDiv>
            <Headline>{craftHomepageHomepageEntry.blogHeadline}</Headline>
            <Subline>{craftHomepageHomepageEntry.blogSubline}</Subline>
            <BlogFeed>
              <BlogItem1>
                <BlogOverlay>
                  <BlogLink
                    to={`blog/${craftHomepageHomepageEntry.blog[0].slug}`}
                  >
                    <h6>{craftHomepageHomepageEntry.blog[0].title}</h6>
                    <p>{craftHomepageHomepageEntry.blog[0].excerpt}</p>
                  </BlogLink>
                </BlogOverlay>
                <BlogImage
                  style={{
                    backgroundImage: `url(${craftHomepageHomepageEntry.blog[0].image[0].url})`,
                  }}
                >
                  {" "}
                </BlogImage>
              </BlogItem1>

              <BlogItem2>
                <BlogOverlay>
                  <BlogLink
                    to={`blog/${craftHomepageHomepageEntry.blog[1].slug}`}
                  >
                    <h6>{craftHomepageHomepageEntry.blog[1].title}</h6>
                    <p>{craftHomepageHomepageEntry.blog[1].excerpt}</p>
                  </BlogLink>
                </BlogOverlay>
                <BlogImage
                  style={{
                    backgroundImage: `url(${craftHomepageHomepageEntry.blog[1].image[0].url})`,
                  }}
                >
                  {" "}
                </BlogImage>
              </BlogItem2>

              <BlogItem3>
                <BlogOverlay>
                  <BlogLink
                    to={`blog/${craftHomepageHomepageEntry.blog[2].slug}`}
                  >
                    <h6>{craftHomepageHomepageEntry.blog[2].title}</h6>
                    <p>{craftHomepageHomepageEntry.blog[2].excerpt}</p>
                  </BlogLink>
                </BlogOverlay>
                <BlogImage
                  style={{
                    backgroundImage: `url(${craftHomepageHomepageEntry.blog[2].image[0].url})`,
                  }}
                >
                  {" "}
                </BlogImage>
              </BlogItem3>

              <BlogItem4>
                <BlogOverlay>
                  <BlogLink
                    to={`blog/${craftHomepageHomepageEntry.blog[3].slug}`}
                  >
                    <h6>{craftHomepageHomepageEntry.blog[3].title}</h6>
                    <p>{craftHomepageHomepageEntry.blog[3].excerpt}</p>
                  </BlogLink>
                </BlogOverlay>
                <BlogImage
                  style={{
                    backgroundImage: `url(${craftHomepageHomepageEntry.blog[3].image[0].url})`,
                  }}
                >
                  {" "}
                </BlogImage>
              </BlogItem4>

              <BlogItem5>
                <BlogOverlay>
                  <BlogLink
                    to={`blog/${craftHomepageHomepageEntry.blog[4].slug}`}
                  >
                    <h6>{craftHomepageHomepageEntry.blog[4].title}</h6>
                    <p>{craftHomepageHomepageEntry.blog[4].excerpt}</p>
                  </BlogLink>
                </BlogOverlay>
                <BlogImage
                  style={{
                    backgroundImage: `url(${craftHomepageHomepageEntry.blog[4].image[0].url})`,
                  }}
                >
                  {" "}
                </BlogImage>
              </BlogItem5>
            </BlogFeed>
            <StyledButton style={{ margin: `0 auto` }}>
              <Link to="/blog">More activities</Link>
            </StyledButton>
          </SytledDiv>
        </Container>
      </StyledSection>
    )}
  />
)

export default Blog
