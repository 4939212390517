import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Headline, Subline, StyledSection } from '../layout/layoutComponents';
import styled from 'styled-components';
import { PartnerNextArrow, PartnerPrevArrow } from '../slider/slider'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  autoplay: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 3
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      }
    }
  ]
};

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <PartnerNextArrow role="arrow"
      className={className}
      onClick={onClick}
      style={{top: '55px'}}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <PartnerPrevArrow role="arrow"
      className={className}
      onClick={onClick}
      style={{top: '55px'}}
    /> 
  );
}
const PartnersContainer = styled.div`
  margin: 0 auto;
  padding: 52px 0px;
  padding-top: 45px;
  max-width: 886px;
`
const PartnerLogo = styled.img`
  height: 100px;
  width: auto;
  margin: 0 auto;
  @media (min-width: 1024px) {
    height: 115px;
  }
`
const PartnerItem = styled.div`
  display: flex;
  width: 85%;
`
const Partners = () => (
  <StaticQuery
    query={graphql`
    query partners {
      craftHomepageHomepageEntry {
        partnersHeadline
        partnersSubline
        companyLogo {
          title
          url
        }
      }
    }
    `}

    render={({craftHomepageHomepageEntry}) => (
      <StyledSection style={{backgroundColor: `rgba(156, 158, 159, 0.1)`, padding: `25px 0`}}>
        <Container>
          <div style={{margin: `0 auto`, textAlign: `center`, paddingTop: '15px'}}>  
            <Headline>{craftHomepageHomepageEntry.partnersHeadline}</Headline>
            <Subline>{craftHomepageHomepageEntry.partnersSubline}</Subline>
            <PartnersContainer>
            <Slider {...settings}>

            {craftHomepageHomepageEntry.companyLogo.map(logo => (
              <PartnerItem>
                <PartnerLogo src={logo.url} alt={logo.title} />
              </PartnerItem>
            ))}
            </Slider>
            </PartnersContainer>
          </div>  
        </Container>
      </StyledSection>
    )}

  />
);

export default Partners;