import styled from 'styled-components'

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  @media (min-width: 1200px) {
    margin-bottom: 150px;
  }
  @media (min-width: 1600px) {
    margin-bottom: 0;
  }
`;

export const StyledFlyers = styled.div`
  z-index: 9999;
  position: fixed;
  right: 0;
  top: 500px;
`;

export const StyledFlyer = styled.div`
  width: 50px;
  height: 50px;
  background-color: white;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0px 5px 6px #00000029;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledWrapper = styled.div`
  margin-top: 68px;
  width: 100%;
  height: 180px;
  position: relative;
  @media (min-width: 500px) {
    height: 280px;
  }
  @media (min-width: 601px) {
    margin-top: 68px;
    height: 300px;
  }
  @media (min-width: 786px) {
    margin-top: 68px;
    height: 420px;
  }
  @media (min-width: 1025px) {
    margin-top: 82px;
    height: 520px;
  }
  @media (min-width: 1600px) {
    height: 720px;
  }
`;

export const StyledContainer = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100vh;
`;

export const HeroContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeroHeader = styled.h1`
  display: none;
  position: absolute;
  top: 0px;
  left: 220px;
  max-width: 210px;
  color: #F28E00;
  font-size: 26px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 700;
  @media (min-width: 500px) {
    display: block;
    line-height: 38px;
    left: 55%;
    font-size: 36px;
    top: 20px;
  };
  @media (min-width: 786px) {
    line-height: 48px;
    left: 65%;
    font-size: 46px;
    top: 20px;
  }
  @media (min-width: 1024px) {
    top: -16px;
    left: 63%;
    line-height: 75px;
    font-size: 75px;
    max-width: 400px;
  }
`;

export const StyledImg = styled.div`
  display: none;
  background: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  right: 0;
  @media (min-width: 725px) {
    display: block;
    top: 160px;
    right: 100px;
    width: 430px;
    height: 350px;
  }  
  @media (min-width: 820px) {
    top: 215px;
    left: 36%;
    width: 500px;
    height: 287px;
  }  
  @media (min-width: 1140px) {
    top: 251px;
    left: 37%;
    width: 525px;
    height: 338px;
  };
  @media (min-width: 1220px) {
    top: 288px;
  };
  @media (min-width: 1450px) {
    top: 325px;
    left: 43%;
    width: 600px;
    height: 360px;
  };
  @media (min-width: 1600px) {
    top: 383px;
    left: 40%;
    width: 667px;
    height: 422px;
  }

`