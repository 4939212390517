import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { StyledSection, StyledWrapper, StyledContainer, HeroContainer, HeroHeader, StyledImg, StyledFlyer, StyledFlyers } from './styledHero'
import { Container } from '../layout/layoutComponents'
import pic1 from '../../images/elemente-1@2x.png'
import pic2 from '../../images/elemente-2@2x.png'
import pic3 from '../../images/elemente-3@2x.png'

const HomeHero = () => (
  <StaticQuery
    query={graphql`
      query {
        craftHomepageHomepageEntry {
          id
          image {
            url
          }
          imageUx {
            url
          }
          heroHeader
        }
      }
    `}

    render={({craftHomepageHomepageEntry}) => (
      <>
          <StyledSection>
            <StyledWrapper>
              <img style={{width: '100%', objectFit: 'contain', maxHeight: `720px`}} src={craftHomepageHomepageEntry.image[0].url} alt=""/>
            </StyledWrapper>
            <StyledContainer>
              <Container>
                <HeroContainer>
                  <HeroHeader>{craftHomepageHomepageEntry.heroHeader}</HeroHeader>
                </HeroContainer>
                <StyledFlyers>
                  <a href="/#transformation" >
                    <StyledFlyer style={{padding: `5px`}}>
                      <img src={pic1} alt=""/>
                    </StyledFlyer>
                  </a>
                  <a href="/move-towards-success">
                    <StyledFlyer>
                    <img src={pic2} alt=""/>
                    </StyledFlyer>
                  </a>
                  <a href="/dare-to-grow">
                    <StyledFlyer>
                    <img src={pic3} alt=""/>
                    </StyledFlyer>
                  </a>
                </StyledFlyers>
              <StyledImg style={{backgroundImage:`url(${craftHomepageHomepageEntry.imageUx[0].url})`}}></StyledImg>
              </Container>
            </StyledContainer>
          </StyledSection>
      </>
    )}

  />
);

export default HomeHero;