import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StyledButton } from "../layout/layoutComponents"

const Contact = styled.div`
  min-width: 250px;
  display: flex;
  margin: 0 auto;
  margin-top: 25px;
  flex-direction: column;
  text-align: left;
  letter-spacing: 0px;
  color: #3c3c3b;
  justify-content: start;
  h6 {
    margin: 0;
    font-weight: 400;
    color: #3c3c3b;
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
  span {
    font-weight: 300;
    color: #3c3c3b;
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
  @media (min-width: 1024px) {
    margin-left: 110px;
    margin-top: 0;
  }
`
const StyledSpan = styled.p`
  font-weight: 300;
  color: #3c3c3b;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  text-transform: capitalize;
`
const ContactInfo = ({ id, name, street, country, city }) => (
  <Contact id={id}>
    <h6>{name}</h6>
    <StyledSpan>{street}</StyledSpan>
    <StyledSpan>{city}</StyledSpan>
    <StyledSpan>{country}</StyledSpan>
    <StyledButton style={{ maxWidth: `140px` }}>
      <Link to="/contact/">Contact</Link>
    </StyledButton>
  </Contact>
)
export default ContactInfo
